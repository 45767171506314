import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';
import Carousel from 'react-multi-carousel';
import { CustomDot } from './CustomDot';

const items = [
  {
    title: 'Wybierz produkty, które Cię interesują.',
    desc: 'Przeglądaj naszą ofertę świeżych owoców prosto z hiszpańskich sadów zrywanych jeszcze z liśćmi dając gwarancję dłuższej soczystości. Każdy owoc jest starannie opisany, abyś mógł wybrać te, które najbardziej Ci odpowiadają. Możesz skorzystać z filtrów, aby znaleźć dokładnie to, czego szukasz - czy to oliwa z oliwek, soczyste pomarańcze, aromatyczne cytryny, czy inne sezonowe owoce.',
    image: '/steps-carousel-1.png',
    count: 1,
  },
  {
    title: 'Potwierdź zamówienie, dostawę oraz sfinalizuj płatność.',
    desc: 'Po wybraniu produktów dodaj je do koszyka i przejdź do finalizacji zamówienia. Podaj swoje dane kontaktowe i adres dostawy, wybierz preferowany sposób wysyłki, a następnie sfinalizuj płatność za pomocą bezpiecznej bramki płatności. Otrzymasz potwierdzenie zamówienia na swój adres e-mail.',
    image: '/steps-carousel-2.png',
    count: 2,
  },
  {
    title: 'Zrywamy świeże owoce z naszych sadów w Hiszpanii.',
    desc: 'Po otrzymaniu Twojego zamówienia, nasi doświadczeni rolnicy w Hiszpanii przystępują do pracy. Owoce są zrywane ręcznie bezpośrednio z drzew, aby zapewnić ich maksymalną świeżość i soczystość. Dokładamy wszelkich starań, aby każdy owoc był starannie wyselekcjonowany i spełniał najwyższe standardy jakości.',
    image: '/steps-carousel-3.png',
    count: 3,
  },
  {
    title:
      'Wysyłamy je bezpośrednio do Ciebie, aby cieszyć się pełnią smaku świeżych owoców!',
    desc: 'Zaraz po zerwaniu owoce są starannie pakowane i przygotowywane do wysyłki. Dzięki naszej efektywnej logistyce, owoce trafiają do Twoich rąk w możliwie najkrótszym czasie. Świeżość i pełnia smaku są naszym priorytetem, dlatego możesz być pewien, że nasze owoce dotrą do Ciebie w idealnym stanie, gotowe do spożycia.',
    image: '/steps-carousel-4.png',
    count: 4,
  },
];

export default function HowItWorksSection() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1800 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1800, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
    },
  };

  return (
    <section
      className={`flex flex-col gap-8 py-12 md:py-24 min-h-[1100px] ${defaultStyles.paddingBig}`}
      style={createStyleForBackgroundImage('/how-it-works-bg.png')}
      id="work"
    >
      <div className="flex flex-col gap-12">
        <div className="flex flex-col gap-4 justify-center items-center text-center">
          <div className="text-[35px] xl:text-[45px] text-white font-extrabold">
            Jak to działa?
          </div>
          <div className="text-[16px] text-center text-white xl:w-[60%]">
            Nasze owoce dostarczamy w kilku prostych krokach, zapewniając
            najwyższą jakość obsługi.
            <br /> Oto jak przebiega proces, od zakupu po dostawę:
          </div>
        </div>
        <Carousel
          responsive={responsive}
          slidesToSlide={1}
          className="py-8 lg:py-12"
          removeArrowOnDeviceType={['tablet', 'mobile']}
          showDots
          customDot={<CustomDot />}
        >
          {items.map((item, index) => (
            <div
              className="flex flex-col-reverse lg:flex-row justify-between items-center gap-4 lg:gap-24"
              key={index}
            >
              <div className="flex flex-col justify-center items-center rounded-full bg-[#94BE1D] p-8 md:p-16">
                <img
                  alt="how-it-works"
                  className="max-w-[180px] max-h-[180px] md:max-w-[320px] md:max-h-[320px] xl:max-w-[420px] xl:max-h-[420px]"
                  src={item.image}
                />
              </div>
              <div className="flex flex-col items-center lg:items-start gap-4">
                <div className="rounded-[70px] bg-white text-[#9BC918] font-bold text-[25px] px-8 py-2">
                  Krok {item.count}
                </div>
                <div className="text-[30px] xl:text-[35px] font-extrabold text-white text-center lg:text-start md:w-[80%]">
                  {item.title}
                </div>
                <div className="text-[16px] text-white md:w-[80%] text-center lg:text-start">
                  {item.desc}
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
}
