import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';
import { useState } from 'react';

const faqItems = [
  {
    title: 'Jakie owoce są dostępne w ofercie?',
    desc: 'W naszej ofercie znajdziesz soczyste pomarańcze, soczyste cytryny oraz wysokiej jakości oliwę z oliwek, wszystkie pochodzące prosto z hiszpańskich sadów.',
  },
  {
    title: 'Skąd pochodzą Wasze owoce?',
    desc: 'Nasze owoce pochodzą z najlepszych sadów i plantacji w Hiszpanii. Każdy owoc jest starannie pielęgnowany, aby zapewnić jego najwyższą jakość, świeżość i soczystość.',
  },
  {
    title: 'Jak szybko otrzymam zamówione owoce?',
    desc: 'Po złożeniu zamówienia, owoce są zrywane ręcznie z drzew i natychmiast wysyłane do Ciebie. Dzięki naszej efektywnej logistyce, owoce trafiają do Twoich rąk w możliwie najkrótszym czasie, zachowując pełnię świeżości i smaku.',
  },
  {
    title: 'Czy mogę zamówić mniejszą ilość niż 10 kg?',
    desc: 'Oferujemy możliwość zakupu już od 10 kg, aby zapewnić najwyższą jakość i świeżość naszych owoców. Niestety, nie mamy opcji zamówienia mniejszej ilości.',
  },
  {
    title: 'Jak mogę zapisać się na grupę WhatsApp?',
    desc: 'Aby zapisać się na naszą grupę WhatsApp, gdzie możesz dzielić się pomysłami na nowe owoce, które moglibyśmy hodować specjalnie dla Ciebie, wystarczy skontaktować się z naszym zespołem obsługi klienta, który chętnie udzieli Ci szczegółowych informacji i pomoże dołączyć do grupy.',
  },
];

function FaqItem(props: { title: string; desc: string }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="flex flex-col gap-4 p-4 bg-[#F6F6F6] rounded-[51px] max-w-[868px] xl:w-[868px]">
      <div className="flex flex-row justify-between gap-8 items-center">
        <div className="text-[25px] font-bold text-black">{props.title}</div>
        <div
          onClick={() => setIsActive(!isActive)}
          className="min-w-[57px] min-h-[57px] w-[57px] h-[57px] flex flex-col justify-center items-center cursor-pointer rounded-full bg-[#F47E01]"
        >
          <svg
            width="19"
            height="11"
            viewBox="0 0 19 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`transition-all duration-300 ${isActive ? 'rotate-180' : ''}`}
          >
            <path
              d="M1.91585 2.0129C2.14552 1.78249 2.51849 1.78188 2.7489 2.01155L8.79407 8.03701C9.18433 8.426 9.81571 8.42599 10.206 8.03701L16.2511 2.01155C16.4815 1.78188 16.8545 1.78249 17.0842 2.0129C17.3138 2.24332 17.3132 2.61629 17.0828 2.84596L10.206 9.7004C9.81571 10.0894 9.18433 10.0894 8.79407 9.7004L1.91721 2.84596C1.68679 2.61629 1.68618 2.24332 1.91585 2.0129Z"
              fill="white"
              stroke="white"
            />
          </svg>
        </div>
      </div>
      {isActive && (
        <div className="text-black text-[16px] w-[70%]">{props.desc}</div>
      )}
    </div>
  );
}

export default function FaqSection() {
  return (
    <section
      className={`flex flex-col xl:flex-row justify-center items-center gap-8 xl:py-44 bg-white ${defaultStyles.padding}`}
      style={createStyleForBackgroundImage('/faq-bg.png', {
        withDarkOverlay: false,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <div className="flex flex-col gap-4 md:w-[70%] xl:w-[30%]">
        <div className="text-[35px] xl:text-[45px] font-extrabold text-[#1E1E1E]">
          <span className="text-[#F47E01]">Najczęściej</span>
          <br />
          zadawane pytania
        </div>
        <div className="text-[16px] text-[#1E1E1E]">
          W tej sekcji znajdziesz odpowiedzi na najczęściej zadawane pytania
          dotyczące naszych produktów, zamówień i dostaw. Jeśli masz dodatkowe
          pytania, skontaktuj się z naszym zespołem obsługi klienta, który
          chętnie pomoże.
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {faqItems.map((item, index) => (
          <FaqItem key={index} {...item} />
        ))}
      </div>
    </section>
  );
}
