import { products } from '@/constants';
import ProductCard from './ProductCard';

export default function ProductCardsGroup(props: {
  noPrice?: boolean;
  shopPageId: number;
}) {
  return (
    <div className="flex flex-row flex-wrap justify-center items-center gap-4">
      {products
        .filter((item) => item.id != props.shopPageId)
        .map((item, index) => (
          <ProductCard key={index} item={item} noPrice={props.noPrice} />
        ))}
    </div>
  );
}
