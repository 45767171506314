import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';

function Card(props: { image: string; title: string; desc: string }) {
  return (
    <div className="max-w-[304px] flex flex-col gap-4 justify-between min-h-[453px]">
      <div className="flex flex-col gap-4">
        <img
          alt="file"
          className="max-w-[304px] max-h-[178px]"
          src={props.image}
        />
        <div className="text-[20px] font-bold text-black">{props.title}</div>
        <div className="text-[16px] text-black">{props.desc}</div>
      </div>
      <button className="border-none outline-none cursor-pointer max-w-[304px] h-[68px] flex flex-row justify-center items-center gap-4 rounded-[86px] bg-[#E67D00]">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.5 11V17L11.5 15"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.5 17L7.5 15"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.5 10V15C22.5 20 20.5 22 15.5 22H9.5C4.5 22 2.5 20 2.5 15V9C2.5 4 4.5 2 9.5 2H14.5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.5 10C15.5 10 14.5 9 14.5 6V2L22.5 10H18.5Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="text-[16px] font-bold uppercase text-white">
          pobierz plik
        </div>
      </button>
    </div>
  );
}

const items = [
  {
    title: 'Jakie są najpopularniejsze produkty z naszego sklepu online.',
    desc: 'Jakie produkty podbijają serca klientów? Sprawdź naszą topową ofertę online!',
    image: '/files-1.png',
  },
  {
    title: 'Dlaczego nasze pomarańcze z Hiszpanii są tak wyjątkowe?',
    desc: 'Czy wiesz, dlaczego pomarańcze z Hiszpanii są uznawane za najlepsze na świecie?',
    image: '/files-2.png',
  },
  {
    title: '6 powodów dlaczego warto sięgać po cytryny.',
    desc: 'Dlaczego cytryny są superfoodem, o którym mało kto mówi?',
    image: '/files-3.png',
  },
  {
    title: 'Oliwa z oliwek - dlaczego warto ją mieć w swojej kuchni?',
    desc: 'Dlaczego oliwa z oliwek jest nazywana "płynnym złotem"? Sprawdź sam!',
    image: '/files-4.png',
  },
];

export default function FilesSection() {
  return (
    <section
      className={`flex flex-col justify-center items-center py-12 gap-8 min-h-[720px] ${defaultStyles.paddingBig}`}
      style={createStyleForBackgroundImage('/materials-bg.png')}
      id="materials"
    >
      <div className="text-center text-[35px] xl:text-[45px] text-black font-extrabold">
        Ściągnij soczyste materiały
      </div>
      <div className="text-center text-[16px] text-black w-[80%]">
        Odkryj nasze soczyste materiały pełne inspiracji i praktycznych porad!
        Pobierz darmowe e-booki, przepisy oraz poradniki, które pomogą Ci w
        pełni wykorzystać potencjał cytryn i pomarańczy w Twojej kuchni.
      </div>
      <div className="flex flex-row flex-wrap justify-center gap-4">
        {items.map((item, index) => (
          <Card key={index} {...item} />
        ))}
      </div>
    </section>
  );
}
