import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { LocalProduct } from '@/utils/types';

export default function InfoProductCard(props: { item: LocalProduct }) {
  return (
    <div
      className={`w-[330px] flex flex-col gap-4 rounded-[35px] overflow-hidden shadow-md`}
    >
      <div
        className="h-[195px] w-[330px]"
        style={createStyleForBackgroundImage(props.item.image)}
      />
      <div className="px-4 flex flex-col gap-4 bg-white">
        <div className="text-[25px] font-bold text-[#1E1E1E]">
          {props.item.title}
        </div>
        <div className="text-[16px] text-[#6B6B6B]">{props.item.desc}</div>
        <div className={`flex flex-row items-center gap-4 border pb-2`}>
          <img alt="spain" width={33} height={33} src="/spain.png" />
          <div className="text-[#6B6B6B] text-[16px]">{props.item.country}</div>
        </div>
      </div>
    </div>
  );
}
