import { products } from '@/constants';
import InfoProductCard from './InfoProductCard';

export default function InfoProductsCardGroup() {
  return (
    <div className="flex flex-row flex-wrap justify-center items-center gap-4">
      {products.map((item, index) => (
        <InfoProductCard key={index} item={item} />
      ))}
    </div>
  );
}
