export const CustomRightArrow = ({
  bgColor,
  svgColor,
  onClick,
  ...rest
}: any) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <div
      className={`absolute bottom-0 left-14 rounded-full w-[39px] h-[39px] ${bgColor ? bgColor : 'bg-white'} flex flex-col justify-center items-center cursor-pointer`}
      onClick={() => onClick()}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.92157 5.17157C10.4837 6.73367 10.4837 9.26633 8.92157 10.8284L5.75 14C5.47386 14.2761 5.02614 14.2761 4.75 14C4.47386 13.7239 4.47386 13.2761 4.75 13L7 10.75C8.51878 9.23122 8.51878 6.76878 7 5.25L4.75 3C4.47386 2.72386 4.47386 2.27614 4.75 2C5.02614 1.72386 5.47386 1.72386 5.75 2L8.92157 5.17157Z"
          fill={svgColor ? svgColor : '#9BC918'}
        />
      </svg>
    </div>
  );
};

export const CustomLeftArrow = ({
  bgColor,
  svgColor,
  onClick,
  ...rest
}: any) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <div
      className={`absolute bottom-0 left-0 rounded-full w-[39px] h-[39px] ${bgColor ? bgColor : 'bg-white'} flex flex-col justify-center items-center cursor-pointer`}
      onClick={() => onClick()}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.07843 10.8284C5.51633 9.26633 5.51633 6.73367 7.07843 5.17157L10.25 2C10.5261 1.72386 10.9739 1.72386 11.25 2C11.5261 2.27614 11.5261 2.72386 11.25 3L9 5.25C7.48122 6.76878 7.48122 9.23122 9 10.75L11.25 13C11.5261 13.2761 11.5261 13.7239 11.25 14C10.9739 14.2761 10.5261 14.2761 10.25 14L7.07843 10.8284Z"
          fill={svgColor ? svgColor : '#9BC918'}
        />
      </svg>
    </div>
  );
};
