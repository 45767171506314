import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { LocalProduct } from '@/utils/types';

export default function ProductCard(props: {
  noPrice?: boolean;
  item: LocalProduct;
}) {
  return (
    <div
      className={`w-[330px] flex flex-col gap-4 rounded-[35px] overflow-hidden shadow-md`}
    >
      <div
        className="h-[195px] w-[330px]"
        style={createStyleForBackgroundImage(props.item.image)}
      />
      <div className="px-4 flex flex-col gap-4 bg-white">
        <div className="text-[25px] font-bold text-[#1E1E1E]">
          {props.item.title}
        </div>
        <div
          className={`flex flex-row items-center gap-4 border pb-2 ${props.noPrice ? '' : 'border-solid border-l-0 border-r-0 border-t-0 border-[#DDDDDD]'}`}
        >
          <img alt="spain" width={33} height={33} src="/spain.png" />
          <div className="text-[#6B6B6B] text-[16px]">{props.item.country}</div>
        </div>
        {!props.noPrice && (
          <>
            <div className="text-[#F47E01] text-[25px] font-bold">
              {props.item.price} ZŁ
            </div>

            <div
              onClick={() => window.location.assign(props.item.route)}
              className="cursor-pointer uppercase text-[13px] md:text-[16px] text-white font-semibold flex flex-row justify-center items-center bg-[#F47E01] outline-none border-none rounded-[63px] min-w-[240px] h-[53px] mb-4"
            >
              <div>Sprawdź</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
