export const CustomDot = ({
  onClick,
  active,
}: {
  onClick?: any;
  active?: any;
}) => {
  return (
    <div
      onClick={(e) => {
        onClick();
        e.preventDefault();
      }}
      className={`bg-white rounded-full self-center transition-all duration-300 cursor-pointer mx-1 ${active ? 'w-[10px] h-[10px]' : 'w-[6px] h-[6px]'}`}
    />
  );
};
