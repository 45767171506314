import API from '@/api';
import { products, QUERY_ID } from '@/constants';
import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';
import { CartResponse, LocalProduct } from '@/utils/types';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

function VerticalCard(props: LocalProduct) {
  const { data: cart, refetch } = useQuery({
    queryKey: [QUERY_ID.GET_CART],
    queryFn: () => API.get('cart/').then((res) => res.data as CartResponse),
  });

  const [isSelected, setIsSelected] = useState(false);
  const [productCount, setProductCount] = useState(0);

  useEffect(() => {
    if (cart && cart.items) {
      const selectedItem = cart.items.filter(
        (item) => item.product.id === Number(props.id),
      )[0];
      if (selectedItem) {
        setIsSelected(true);
        setProductCount(selectedItem.quantity);
      }
    }
  }, [cart]);

  const handleAddToCart = async () => {
    await API.post('cart/add/', { product_id: props.id, quantity: 1 });
    setProductCount(1);
    setIsSelected(true);
    refetch();
  };

  const handleRemoveFromCart = async () => {
    await API.post('cart/remove/', { product_id: props.id });
    setIsSelected(false);
    setProductCount(0);
    refetch();
  };

  const handleIncrement = async () => {
    await API.post('cart/update/', {
      product_id: props.id,
      quantity: productCount + 1,
    });
    setProductCount((prevCount) => prevCount + 1);
    await API.get('cart/');
    refetch();
  };

  const handleDecrement = async () => {
    if (productCount === 1) {
      handleRemoveFromCart();
      refetch();
    } else {
      await API.post('cart/update/', {
        product_id: props.id,
        quantity: productCount - 1,
      });
      setProductCount((prevCount) => prevCount - 1);
    }
    refetch();
  };
  return (
    <div className="rounded-[12px] flex flex-col w-full max-w-[870px] md:max-w-[428px] overflow-hidden h-[469px]">
      <div
        className="h-[195px] "
        style={createStyleForBackgroundImage(props.image, {
          withDarkOverlay: false,
          backgroundPosition: 'top',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        })}
      ></div>
      <div className="bg-[#F0F0F0] flex flex-col gap-4 px-4 py-4 rounded-b-[12px]">
        <div
          onClick={() => window.location.assign(props.route)}
          className="text-[#1E1E1E] text-[25px] font-bold hover:text-[#F47E01] transition-all duration-300 cursor-pointer"
        >
          {props.title}
        </div>
        <div className="flex flex-row items-center gap-4 border pb-2 border-solid border-l-0 border-r-0 border-t-0 border-[#DDDDDD]">
          <img alt="spain" width={33} height={33} src="/spain.png" />
          <div className="text-[#6B6B6B] text-[16px]">{props.country}</div>
        </div>
        <div className="text-[#F47E01] text-[25px] font-bold">
          {props.price} ZŁ
        </div>
        {!isSelected && (
          <div
            onClick={handleAddToCart}
            className="cursor-pointer uppercase text-[13px] md:text-[16px] text-white font-semibold flex flex-row justify-center items-center bg-[#F47E01] outline-none border-none rounded-[63px] min-w-[240px] h-[53px]"
          >
            <div>Dodaj do koszyka</div>
          </div>
        )}
        {isSelected && (
          <>
            <div className="border border-solid border-[#F47E01] text-black font-semibold flex flex-row justify-between items-center rounded-[63px] min-w-[240px] h-[53px] px-2">
              <div
                onClick={handleDecrement}
                className="cursor-pointer w-[42px] h-[38px] bg-[#F47E01] rounded-full flex flex-col justify-center items-center text-center pb-1"
              >
                <div className="font-bold text-white text-[16px]">-</div>
              </div>
              <div>
                {props.weight * productCount} {props.unit} (
                {props.price * productCount} zł)
              </div>
              <div
                onClick={handleIncrement}
                className="cursor-pointer w-[42px] h-[38px] bg-[#F47E01] rounded-full flex flex-col justify-center items-center text-center pb-1"
              >
                <div className="font-bold text-white text-[16px]">+</div>
              </div>
            </div>
            <div
              className="text-[#FF6745] font-bold text-[16px] cursor-pointer self-center"
              onClick={handleRemoveFromCart}
            >
              Usuń z koszyka
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default function OurProductsSection() {
  return (
    <section
      id="order"
      className={`flex flex-col justify-center items-center gap-4 bg-white ${defaultStyles.padding}`}
      style={createStyleForBackgroundImage('/products-bg.png', {
        withDarkOverlay: false,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <div className="text-[35px] xl:text-[45px] text-black font-extrabold flex flex-col justify-center">
        Nasze produkty
      </div>
      <div className="text-[16px] text-[#6B6B6B] font-[400] text-center xl:w-[40%]">
        Ciesz się najwyższą jakością i naturalnym, soczystym smakiem naszych
        owoców, które wraz z liśćmi są zrywane prosto z drzew i natychmiast
        wysyłane do Ciebie.
      </div>
      <div className="flex flex-row flex-wrap w-full justify-center items-center gap-4">
        {products.map((item, index) => (
          <VerticalCard {...item} key={index} />
        ))}
      </div>
    </section>
  );
}
