import { defaultStyles } from '@/utils/defaultStyles';
import InfoProductsCardGroup from './InfoProductsCardGroup';
import ProductCardsGroup from './ProductCardsGroup';

export default function WhatDoWeSellSection(props: { shopPageId?: number }) {
  return (
    <section
      className={`flex flex-col justify-center items-center gap-12 py-12 md:py-24 ${defaultStyles.paddingBig}`}
    >
      {props.shopPageId ? (
        <div className="flex flex-col gap-4">
          <div className="text-[35px] xl:text-[45px] font-bold text-[#1E1E1E] text-center">
            Nasze produkty
          </div>
          <div className="text-[16px] text-[#6B6B6B] text-center">
            Ciesz się najwyższą jakością i naturalnym, soczystym smakiem naszych
            owoców.
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="text-[35px] xl:text-[45px] font-bold text-[#1E1E1E]">
            Jakie produkty sprzedajemy?
          </div>
        </div>
      )}
      {props.shopPageId ? (
        <ProductCardsGroup shopPageId={props.shopPageId} />
      ) : (
        <InfoProductsCardGroup />
      )}
    </section>
  );
}
