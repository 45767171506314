import AspectsSection from '@/components/AspectsSection';
import BlogPreviewSection from '@/components/BlogPreviewSection';
import ClientReviewsSection from '@/components/ClientReviewsSection';
import ClientVideosSection from '@/components/ClientVideosSection';
import FaqSection from '@/components/FaqSection';
import FilesSection from '@/components/FilesSection';
import HeroSection from '@/components/HeroSection';
import HowItWorksSection from '@/components/HowItWorksSection';
import MainPageBannerSection from '@/components/MainPageBannerSection';
import NewsletterSection from '@/components/NewsletterSection';
import OurProductsSection from '@/components/OurProductsSection';
import SignInSection from '@/components/SignInSection';
import WhatDoWeSellSection from '@/components/WhatDoWeSellSection';
import Head from 'next/head';

export default function Home() {
  return (
    <>
      <Head>
        <title>Strona główna</title>
        <meta name="description" content="Strona główna firmy Pomarańcze" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <HeroSection />
      <OurProductsSection />
      <MainPageBannerSection />
      <HowItWorksSection />
      <AspectsSection />
      <WhatDoWeSellSection />
      <SignInSection />
      <FaqSection />
      <BlogPreviewSection />
      <FilesSection />
      <ClientReviewsSection />
      <ClientVideosSection />
      <NewsletterSection />
    </>
  );
}
