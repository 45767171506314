import API from '@/api';
import { QUERY_ID } from '@/constants';
import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';
import { getMediaLink } from '@/utils/getMediaLink';
import { BlogResponse } from '@/utils/types';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

function BigCard(props: {
  title: string;
  desc: string;
  id: number;
  image: string;
}) {
  return (
    <div className="flex flex-col gap-4 xl:max-w-[868px] bg-[#F5F5F5] rounded-[38px] p-4  text-black hover:text-[#F47E01] transition-all duration-300">
      <img
        alt="blog-image"
        className="w-full max-w-[868px] max-h-[434px]"
        src={getMediaLink(props.image)}
      />
      <div className="font-bold text-[25px] text-inherit">{props.title}</div>
      <div className="text-black text-[16px] w-[80%]">{props.desc}</div>
      <button
        onClick={() => window.location.assign('/blog/' + props.id)}
        className="max-w-[177px] max-h-[53px] px-4 py-2 rounded-[72px] bg-[#F47E01] border-none outline-none cursor-pointer text-white font-bold text-[20px]"
      >
        Czytaj dalej
      </button>
    </div>
  );
}

function SmallCard(props: { title: string; image: string; id: number }) {
  return (
    <div className="flex flex-row items-center gap-4 max-w-[417px] max-h-[278px] bg-[#F5F5F5] rounded-[38px] p-4 text-black hover:text-[#F47E01] transition-all duration-300">
      <img
        alt="blog-image"
        className="max-w-[169px] max-h-[178px]"
        src={getMediaLink(props.image)}
      />
      <div className="flex flex-col gap-4 text-inherit">
        <div className="text-[18px] font-bold text-inherit">{props.title}</div>
        <button
          onClick={() => window.location.assign('/blog/' + props.id)}
          className="max-w-[177px] max-h-[53px] px-4 py-2 rounded-[72px] bg-[#F47E01] border-none outline-none cursor-pointer text-white font-bold text-[20px]"
        >
          Czytaj dalej
        </button>
      </div>
    </div>
  );
}

export default function BlogPreviewSection() {
  const [fetchedPosts, setFetchedPosts] = useState<
    undefined | BlogResponse[]
  >();

  const { data: posts } = useQuery({
    queryKey: [QUERY_ID.GET_POSTS],
    queryFn: () =>
      API.get('blog/posts/').then((res) => res.data as BlogResponse[]),
  });

  useEffect(() => {
    if (posts) {
      setFetchedPosts(posts);
    }
  }, [posts]);

  return (
    <section
      className={`flex flex-col justify-center items-center py-12 gap-8 bg-white ${defaultStyles.paddingBig}`}
      style={createStyleForBackgroundImage('/blog-preview-bg.png', {
        withDarkOverlay: false,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      })}
      id="blog"
    >
      <div className="flex flex-col gap-4">
        <div className="text-[35px] xl:text-[45px] font-extrabold text-[#1E1E1E]">
          Kuchnia wiedzy
        </div>
        <div className="text-[16px] text-[#6B6B6B] w-[90%]">
          Odkryj fascynujący świat kulinarnych zastosowań cytryn i pomarańczy. W
          naszym blogu "Kuchnia wiedzy" znajdziesz inspirujące przepisy, porady
          i pomysły na wykorzystanie tych owoców w codziennym gotowaniu,
          pieczeniu i nie tylko. Ciesz się zdrowymi, smacznymi i kreatywnymi
          daniami, które wzbogacą Twoją kuchnię.
        </div>
      </div>
      {fetchedPosts && (
        <div className="flex flex-col xl:flex-row items-start gap-8">
          <BigCard
            title={fetchedPosts[0].title}
            desc={fetchedPosts[0].shortDesc}
            id={fetchedPosts[0].id}
            image={fetchedPosts[0].image}
          />
          <div className="flex flex-row flex-wrap xl:flex-col gap-4">
            {fetchedPosts.slice(1, 4).map((item, index) => (
              <SmallCard
                key={index}
                image={item.image}
                title={item.title}
                id={item.id}
              />
            ))}
          </div>
        </div>
      )}
    </section>
  );
}
