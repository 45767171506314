import { defaultStyles } from '@/utils/defaultStyles';
import Carousel from 'react-multi-carousel';
import { CustomLeftArrow, CustomRightArrow } from './CustomCarouselArrows';
import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';

function Slide(props: {
  username: string;
  bio: string;
  review: string;
  igLink?: string;
  webLink?: string;
}) {
  return (
    <div className="mx-4 max-w-[420px] flex flex-col rounded-[38px] overflow-hidden">
      <div className="bg-[#D27410] p-4 flex flex-col gap-4 items-start">
        <img alt="quote" src="/quote.png" width={72} height={57} />
        <div className="text-white text-[15px] min-h-[340px]">
          {props.review}
        </div>
      </div>
      <div className="bg-white flex flex-col items-start gap-4 p-4 min-h-[180px]">
        <div className="text-[20px] text-black font-extrabold">
          {props.username}
        </div>
        <div className="text-[16px] text-black">{props.bio}</div>
        {props.igLink && (
          <a href={props.igLink} target="_blank">
            <img
              alt="instagram"
              width={43}
              height={43}
              src="/insta.png"
              className="cursor-pointer"
            />
          </a>
        )}
        {props.webLink && (
          <a href={props.webLink} target="_blank">
            <img
              alt="web"
              width={43}
              height={43}
              src="/www.png"
              className="cursor-pointer"
            />
          </a>
        )}
      </div>
    </div>
  );
}

const slides = [
  {
    username: 'Kamila Krajewska',
    bio: 'prawnik, content creator i #foodlover',
    review:
      '"Do tej pory nie byłam świadoma ogromnej różnicy pomiędzy sklepowymi pomarańczami, a tymi od pomarancze.com. ,które są soczyste, świeże, naturalne i bez zbędnych barwników. Uwielbiam robić z nich m.in. wyciskane soki - możesz uzyskać szklankę soku z 2 niewielkich pomarańczy! Polecam soczyście!"',
    igLink: 'https://www.instagram.com/_kamilcia_k?igsh=c21qdnkzZ2w3c2tz',
  },
  {
    username: 'Sylwia Wieczorek',
    bio: 'właścicielka Joy Village luksusowej willi na wynajem w Radości pod Warszawą',
    review:
      '"Niedawno miałam przyjemność delektować się paczką pomarańczy, na której etykiecie widniał uroczy napis: "Ta paczka powoduje wiele radości u odbiorcy!" Te pomarańcze były nie tylko wyjątkowo soczyste, ale i słodkie, co od razu skojarzyło mi się z moim biznesem – wynajmem domu w Radości, Joy Village. Tak jak te pyszne owoce, pobyt w Joy Village przynosi mnóstwo radości i uśmiechu. Gorąco polecam zarówno te soczyste i słodkie pomarańcze, jak i odwiedzenie Joy Village, aby doświadczyć prawdziwej radości w Radości 😉 A ja już czekam na nową dostawę tych pysznych 🍊🍊',
    igLink:
      'https://www.instagram.com/joyvillage_13?igsh=Zm9kOXduaW8xY285&utm_source=qr',
  },
  {
    username: 'Marcin Rybak',
    bio: 'doradca biznesowy 🎯 trener mentalny 🎯 mówca motywacyjny 🎯 📍 Przedsiębiorca💰inwestor 🔍 wizjoner 🚀 przyszły miliarder',
    webLink: 'https://www.zwyciezam.pl',
    review:
      '"Jestem absolutnie zachwycony jakością pomarańczy z liściem ze słonecznej Hiszpanii w dwóch czerwcowych dostawach. Ich smak jest niezrównany, a aromat po prostu rozkoszny. Jako osoba, która dużo podróżuje po świecie i lubi produkty premium, mogę śmiało powiedzieć, że te pomarańcze są jednym z najlepszych owoców, jakie miałem okazję spróbować w życiu. Jak wiecie lubię szybkie samochody, dlatego również dostarczamy produkty expressem ;-) Zrywamy je w poniedziałek i w czwartek/piątek są już u Ciebie. Sprowadzam je dla Was z Murcji w Hiszpanii ;-)! Polecam je wszystkim moim znajomym i gorąco zachęcam do ich wypróbowania: „Poczuj ten smak”! Jestem pewny, że zakochasz się w nich tak samo jak ja :)"',
  },
];

export default function ClientReviewsSection() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1424 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1424, min: 764 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 764, min: 0 },
      items: 1,
    },
  };

  return (
    <section
      className={`flex flex-col gap-8 py-12 md:py-24 bg-[#E67D00] min-h-[1500px] md:min-h-[1080px] ${defaultStyles.paddingBig}`}
      style={createStyleForBackgroundImage('/client-review-bg.png')}
      id="reviews"
    >
      <div className="flex flex-col gap-4 items-center justify-center">
        <div className="text-[35px] xl:text-[45px] text-white font-extrabold">
          Sprawdź co sądzą nasi klienci
        </div>
        <div className="text-[16px] text-white w-[80%] text-center">
          Przeczytaj opinie naszych zadowolonych klientów i dowiedz się,
          dlaczego wybierają nasze świeże i soczyste owoce. Sprawdź, jak nasze
          produkty zmieniają ich codzienne gotowanie i pieczenie.
          <br /> Ciesz się zaufaniem, które budujemy dzięki najwyższej jakości i
          doskonałej obsłudze.
        </div>
      </div>
      <Carousel
        responsive={responsive}
        slidesToSlide={1}
        infinite
        customRightArrow={<CustomRightArrow svgColor="#F47E01" />}
        customLeftArrow={<CustomLeftArrow svgColor="#F47E01" />}
        className="pb-12"
      >
        {slides.map((item, index) => (
          <Slide {...item} key={index} />
        ))}
      </Carousel>
    </section>
  );
}
