import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';

export default function SignInSection() {
  return (
    <section
      className={`flex flex-col justify-center items-center py-12 md:py-24 min-h-[800px] ${defaultStyles.paddingBig}`}
      style={createStyleForBackgroundImage('/sign-in-bg.png')}
    >
      <div className="flex flex-col items-center justify-center gap-4">
        <div className="text-[35px] xl:text-[45px] font-extrabold text-white text-center">
          Jesteś ciekaw smaków Hiszpanii? <br />
          Soczyste produkty zamów już dziś!
        </div>
        <div className="text-[16px] text-white text-center xl:w-[50%]">
          Masz pomysł, jakie jeszcze owoce dla Ciebie możemy hodować? Dołącz do
          naszej grupy i podziel się pomysłami!
        </div>
        <div className="text-[16px] text-white text-center mt-8 xl:w-[50%]">
          Podziel się swoimi pomysłami na nowe owoce, które moglibyśmy hodować
          specjalnie dla Ciebie! Czekamy na Twoje sugestie i opinie, aby
          dostarczać Ci najlepsze produkty prosto z naszych sadów.
        </div>
        <button
          onClick={() =>
            window.open(
              'https://chat.whatsapp.com/LSSBOD0kvOiBYJgdvhYMbV',
              '_blank',
            )
          }
          className="mt-4 cursor-pointer uppercase text-[13px] md:text-[16px] text-white font-semibold flex flex-row justify-center items-center bg-[#F47E01] outline-none border-none rounded-[63px] min-w-[240px] h-[53px]"
        >
          <div>Zapisz się</div>
        </button>
      </div>
    </section>
  );
}
