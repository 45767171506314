import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';

export default function MainPageBannerSection() {
  return (
    <section
      className={`flex flex-col xl:flex-row gap-8 items-center justify-between py-12 md:py-24 ${defaultStyles.paddingBig}`}
      style={createStyleForBackgroundImage('/banner-bg.png')}
    >
      <div className="flex flex-col gap-8 xl:w-[40%]">
        <div className="text-[35px] xl:text-[45px] font-extrabold text-black">
          <span className="text-[#F47E01]">Skąd pochodzą</span>
          <br />
          nasze produkty?
        </div>
        <div className="text-[16px] text-[#1E1E1E]">
          Nasze produkty pochodzą z najlepszych sadów i plantacji, gdzie każdy
          owoc jest starannie pielęgnowany. Dbamy o to, by nasze owoce były
          uprawiane w optymalnych warunkach, dzięki czemu zachowują swoją
          naturalną świeżość i soczystość.
          <br />
          <br /> Codziennie zrywamy i wysyłamy owoce bezpośrednio do Ciebie,
          gwarantując najwyższą jakość i smak.
        </div>
      </div>
      <img
        alt="banner"
        loading="lazy"
        className="max-w-[300px] max-h-[287px] md:max-w-[758px] md:max-h-[726px]"
        src="/main-page-banner.png"
      />
    </section>
  );
}
