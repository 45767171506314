import API from '@/api';
import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';
import { Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  mail: Yup.string()
    .email('Nieprawidłowy adres e-mail')
    .required('E-mail jest wymagany'),
});

export default function NewsletterSection() {
  return (
    <section
      className={`flex flex-col justify-center items-center py-12 md:py-24 min-h-[800px] ${defaultStyles.paddingBig}`}
      style={createStyleForBackgroundImage('/newsletter-bg.png')}
    >
      <div className="flex flex-col items-center justify-center gap-8">
        <div className="text-[35px] xl:text-[45px] font-extrabold text-white text-center xl:w-[50%]">
          Nie przegap żadnej dostawy, zapisz się do Newslettera już teraz!
        </div>
        <div className="text-[16px] text-white text-center xl:w-[50%]">
          Zapisz się do naszego newslettera, aby otrzymywać regularne
          aktualizacje i ekskluzywne oferty. Dołącz do naszej społeczności
          miłośników świeżych i soczystych owoców!
        </div>
        <Formik
          initialValues={{
            mail: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await toast.promise(
              API.post('newsletter/subscribe/', { email: values.mail }),
              {
                pending: 'Zapisywanie do newslettera...',
                success: 'Zapisano do newslettera',
                error: 'Wystąpił błąd przy zapisywaniu',
              },
            );
          }}
        >
          {({ values, errors, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="max-w-[661px] flex flex-row h-[65px] rounded-[86px] overflow-hidden">
                <Field
                  placeholder="Wpisz swój adres e-mail"
                  className="outline-none border-none px-6"
                  name="mail"
                  value={values.mail}
                />
                <button
                  type="submit"
                  className="cursor-pointer uppercase text-[13px] md:text-[16px] text-white font-semibold flex flex-row justify-center items-center bg-[#F47E01] outline-none border-none min-w-[100px] lg:min-w-[150px] h-[65px]"
                >
                  <div>Zapisz się</div>
                </button>
              </div>
              {Object.keys(errors).length > 0 && (
                <div className="text-red-500 text-xs">
                  <ul>
                    {Object.values(errors).map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
            </form>
          )}
        </Formik>
      </div>
    </section>
  );
}
