import { defaultStyles } from '@/utils/defaultStyles';
import Carousel from 'react-multi-carousel';
import { CustomLeftArrow, CustomRightArrow } from './CustomCarouselArrows';
import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';

function Slide(props: { username: string; video: string }) {
  return (
    <div className="mx-4 max-w-[420px] max-h-[522px] flex flex-col rounded-[38px] overflow-hidden">
      <div className="bg-[#F47E01] h-[374px] flex flex-col justify-center items-center">
        <video height={374} controls>
          <source src={props.video} type="video/mp4" />
        </video>
      </div>
      <div className="bg-[#F4F4F4] flex flex-row items-center gap-4 p-4">
        <div className="text-[20px] font-bold text-black">{props.username}</div>
      </div>
    </div>
  );
}

const slides = [
  {
    username: 'Paweł Jaguś',
    video: '/videos/pawel-jagus.mp4',
  },
  {
    username: 'Renata Krzyczkowska',
    video: '/videos/renata-krzyczkowska.mp4',
  },
  {
    username: 'Sylwia Wieczorek',
    video: '/videos/sylwia-wieczorek.mp4',
  },
  {
    username: 'Michał Zawadka',
    video: '/videos/michal-zawadka.mp4',
  },
  {
    username: 'Darek',
    video: '/videos/darek.mp4',
  },
  {
    username: 'Joanna Kolczyńska i Łukasz Stawicki',
    video: '/videos/joanna-lukasz-1.mp4',
  },
  {
    username: 'Joanna Kolczyńska i Łukasz Stawicki',
    video: '/videos/joanna-lukasz-2.mp4',
  },
  {
    username: 'Adam Kin',
    video: '/videos/adam-kin.mp4',
  },
];

export default function ClientVideosSection() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1424 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1424, min: 764 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 764, min: 0 },
      items: 1,
    },
  };

  return (
    <section
      className={`flex flex-col gap-8 py-12 xl:py-32 ${defaultStyles.paddingBig}`}
      style={createStyleForBackgroundImage('/videos-bg.png')}
    >
      <div className="flex flex-col gap-4 items-center justify-center">
        <div className="text-[35px] xl:text-[45px] text-black font-extrabold">
          Filmy od naszych klientów
        </div>
        <div className="text-[16px] text-black w-[80%]">
          Zobacz, jak nasi klienci wykorzystują nasze świeże owoce w swoich
          kuchniach! Dowiedz się, dlaczego tak wielu ludzi uwielbia nasze
          produkty.
        </div>
      </div>
      <Carousel
        responsive={responsive}
        slidesToSlide={1}
        infinite
        customRightArrow={
          <CustomRightArrow bgColor="bg-[#F47E01]" svgColor="#FFFFFF" />
        }
        customLeftArrow={
          <CustomLeftArrow bgColor="bg-[#F47E01]" svgColor="#FFFFFF" />
        }
        className="pb-12"
      >
        {slides.map((item, index) => (
          <Slide {...item} key={index} />
        ))}
      </Carousel>
    </section>
  );
}
